import React, { useEffect } from "react";
import { ISourceTopicFields } from "src/types/generated/contentful";
import {
  SourceTopicContext,
  SourceTopicContextType,
} from "./SourceTopicContext";

export type SourceTopicReaderProps = {
  sources: ISourceTopicFields[];
};

const SourceTopicReader = (data: SourceTopicReaderProps) => {
  const { sources } = data;

  const list =
    sources && sources.length > 0
      ? sources.map((source, i) => {
          return (
            <li key={i}>
              <a href={source.url} target="_blank" rel="noopener noreferrer">
                {source.url}
              </a>
            </li>
          );
        })
      : null;
  return <ol>{list}</ol>;
};

export default React.memo(SourceTopicReader);
