import React, { ReactNode, Attributes, useEffect } from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled, { DefaultTheme, StyledComponent } from "styled-components";

import FlexDisplay from "../FlexDisplay";
import FlexDisplayItem from "../FlexDisplayItem";
import Section from "../Section";
import { IFootnoteFields } from "src/types/generated/contentful";
import {
  SourceTopicContext,
  SourceTopicContextType,
} from "../SourceTopic/SourceTopicContext";
import SourceTopicReader from "../SourceTopic/SourceTopicReader";

const Title = styled.h4``;
const ContentWrapper = styled.div`
  text-align: left;
  position: relative;

  li,
  p {
    line-height: ${(props: any) =>
      props.theme.typography.sm.lineHeight || 1.5}rem;
    font-size: ${(props: any) => props.theme.typography.sm.fontSize || 1}rem;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5em;
  }
`;

type FootnoteProps = { "data-layoutid"?: string } & IFootnoteFields;

export default function Footnote(data: FootnoteProps): JSX.Element {
  const { complianceNumber, disclaimer } = data;

  const { sources: sourcesFromPageContext } = React.useContext(
    SourceTopicContext
  ) as SourceTopicContextType;

  const hasSources =
    sourcesFromPageContext && sourcesFromPageContext.length > 0;

  return (
    <>
      <Section technicalId="sources">
        <FlexDisplay
          title="Sources"
          direction="Column"
          data-layoutid={data["data-layoutid"]}
          content={[]}
          flexDisplayHorizontalAlignment={"Left"}
          flexDisplayVerticalAlignment={"Top"}
        >
          <FlexDisplayItem>
            <ContentWrapper>
              {hasSources ? (
                <>
                  <Title>Sources</Title>
                  <SourceTopicReader
                    sources={sourcesFromPageContext}
                    data-layoutid={data["data-layoutid"]}
                  />
                </>
              ) : null}

              <br />
              {disclaimer ? documentToReactComponents(disclaimer) : null}
              <br />
              {complianceNumber}
              <br />
            </ContentWrapper>
          </FlexDisplayItem>
        </FlexDisplay>
      </Section>
    </>
  );
}
